
.app-container {
    min-height: 350px;
}


.wrapper-class {
    padding: 0.1rem;
    border: 1px solid #ccc;
  }
  .editor-class {
    background-color:rgb(224, 224, 224);
    padding: 0.5rem;
    border: 1px solid #ccc;
    overflow: scroll;
    height: 65vh;
  }

  @media screen and (max-width:600px){
    .editor-class {
      background-color:rgb(224, 224, 224);
      padding: 0.5rem;
      border: 1px solid #ccc;
      overflow: scroll;
      height: 50vh;
    }
  }

  .toolbar-class {
    border: 1px solid #ccc;
    display: flex;
  }
  
  .public-DraftStyleDefault-block {
    margin: 0.5em 0;
  }

  .rdw-colorpicker-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap
  }
  .rdw-colorpicker-modal {
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 175px;
    height: 175px;
    border: 1px solid #F1F1F1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #BFBDBD;
  }
  .rdw-colorpicker-modal-header {
    display: flex;
    padding-bottom: 5px;
  }
  .rdw-colorpicker-modal-style-label {
    font-size: 15px;
    width: 50%;
    text-align: center;
    cursor: pointer;
    padding: 0 10px 5px;
  }
  .rdw-colorpicker-modal-style-label-active {
    border-bottom: 2px solid #0a66b7;
  }
  .rdw-colorpicker-modal-options {
    margin: 5px auto;
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    overflow: scroll;
  }
  .rdw-colorpicker-cube {
    width: 22px;
    height: 22px;
    border: 1px solid #F1F1F1;
  }
  .rdw-colorpicker-option {
    margin: 3px;
    padding: 0;
    min-height: 20px;
    border: none;
    width: 22px;
    height: 22px;
    min-width: 22px;
    box-shadow: 1px 2px 1px #BFBDBD inset;
  }
  .rdw-colorpicker-option:hover {
    box-shadow: 1px 2px 1px #BFBDBD;
  }
  .rdw-colorpicker-option:active {
    box-shadow: -1px -2px 1px #BFBDBD;
  }
  .rdw-colorpicker-option-active {
    box-shadow: 0px 0px 2px 2px #BFBDBD;
  }

  /** Text Editor Custom CSS **/
  .rdw-option-wrapper {
    border: 1px solid #F1F1F1;
    padding: 0px;
    min-width: 25px;
    height: 20px;
    border-radius: 2px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
  }

  .rdw-inline-wrapper {
    display: flex;
    align-items: center;
    /* margin-bottom: 6px; */
    flex-wrap: wrap;
}

.rdw-block-dropdown {
  width: 75px;
}

.rdw-fontfamily-dropdown {
  width: 50px;
}

.rdw-editor-toolbar {
  padding: 0;
}